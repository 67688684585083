.footer a {
  text-decoration: none;
  transition: color 0.2s;
  color: rgb(254, 119, 0);
  font-size: larger;
  padding: 5px;
}

.footer {
  background-color: rgb(36, 39, 43);
  color: #cccccc96;
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 20px 0;
  text-align: center;
}
.footer a:hover {
  color: #ccc;
}

.footer p {
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}
@media (max-width: 768px) {
    .footer {
      padding: 15px 0; 
    }
  
    .footer p {
      font-size: 0.7rem; 
    }
  
    .footer a {
      font-size: 0.8rem;
      padding: 3px; 
    }
  }