.hero {
    height: 100vh;
    color: #fff;
  }
  

.hero .text-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
   
  }
  
  .hero h1 {
    margin-bottom: 1.5rem; 
    font-size: 4em;
    color: rgba(255, 255, 255, 0.703);
  }
  
  .hero .btn-light {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    background-color: #ffffffc7;
  }

  @media (max-width: 768px) {
    .hero h1 {
        font-size: 2rem; 
    }
    
    .hero .btn-light {
        font-size: 1rem; 
    }
}