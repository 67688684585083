.navbar-custom {
  background-color: #000000ab;
  position: sticky;
  font-size: 0.8rem;
}

.navbar-custom .navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-custom .navbar-brand img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.navbar-custom .navbar-brand span {
  color: #ffffffc1;
  font-weight: bold;
  font-size: 1.5rem;
}

.navbar-custom .nav-link {
  color: #fff !important;
  margin-left: 20px;
}

.navbar-custom .container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}


@media (max-width: 768px) {
  .navbar-custom .navbar-brand span {
    font-size: 1.2rem; 
  }

  .navbar-custom .nav-link {
    font-size: 0.9rem; 
  }
 
  .navbar-custom .navbar-collapse {
    text-align: center;
    color: #ffffff;
  }
  .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.6) !important;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  }
}