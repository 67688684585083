*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: url(./background.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Bitter", system-ui !important;
  font-weight: 400;
  font-style: normal;
  background-attachment: fixed;
}


.bitter-bold {
  font-family: "Bitter", serif !important;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.special-elite-regular {
  font-family: "Special Elite", system-ui !important;
  font-weight: 400;
  font-style: normal;
}

.dancing-script {
  font-family: "Dancing Script", cursive !important;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 2rem;
  font-style: normal;
}

.link{
  text-decoration: none; 
}

    